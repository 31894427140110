//
// Root
//

html {
  font-family: sans-serif;
  text-size-adjust: 100%;
}

html,
body {
    height: 100%;
    margin: 0px;
    padding: 0px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: $root-font-size !important;
    font-weight: $font-weight-base;
    font-family: $font-family-sans-serif;
    //background-color: #ff6f0f !important; //orange theme --manu

    // Tablet mode
    @include media-breakpoint-down(lg) {
        font-size: $root-font-size-lg !important;
    }

    // Mobile mode
    @include media-breakpoint-down(md) {
        font-size: $root-font-size-md !important;
    }    
}

body {
    display: flex;
    flex-direction: column;

    a:hover,
    a:active,
    a:focus {
        text-decoration: none !important;
    }
}

canvas {
    user-select: none;
}

// Angular integration
router-outlet {
    display: none;
}

.text-orange{
    color: $orange !important;
}
.bg-orange{
    background-color: $orange !important;
}
.btn.btn-orange{
    background-color: $orange !important;
    color: #ccc;
}
.btn.btn-orange :hover{
    background-color: var(--bs-orange) !important;
    color: #ccc;
}

.bg-orange-grad{
    background: linear-gradient(to right top, #fe5000, #f77301, #e98205, #db951b, #d79d0a);
}

// .bg-pink-grad{
//     background: linear-gradient(to right top, #d6ad46, #df9c2b, #e98810, #f37000, #fe5000);
// }

.bg-purple-grad{
    background: linear-gradient(to left top, #6723aa, #9b0098, #bf0083, #d6006c, #e42855);
}
.bg-green-grad{
    background: linear-gradient(to right top, #051937, #004d7a, #008793, #00bf72, #a8eb12);
}

.bg-org-pnk-prpl{
    color:#fff;
    background-image: linear-gradient(to right, #fe5000, #fc451f, #f93b2f, #f4333d, #ee2d48, #e81d55, #e00c61, #d6006c, #c6007d, #af008e, #900d9d, #6723aa);
}
.bg-org-pnk-prpl:hover{
    background-image: linear-gradient(to left, #fe5000, #fc451f, #f93b2f, #f4333d, #ee2d48, #e81d55, #e00c61, #d6006c, #c6007d, #af008e, #900d9d, #6723aa);
}

.dark-footer{
    border-top: 1px solid rgba(255, 255, 255, 0.3); 
    background-color: rgba(255, 87, 34, 0.25);
}

.text-gradient{
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
}

.topbar .btn.btn-custom i{
    color: var(--bs-text-muted) !important;
}


/* ===== Scrollbar CSS ===== */
  /* Firefox */
  * {
    scrollbar-width: thin;
    scrollbar-color: #808290 #303030;
  }

  /* Chrome, Edge, and Safari */
  *::-webkit-scrollbar {
    width: 12px;
  }

  *::-webkit-scrollbar-track {
    background: #303030;
  }

  *::-webkit-scrollbar-thumb {
    background-color: #808290;
    border-radius: 6px;
    border: 0px solid #ffffff;
  }

  .h-180px{
    height: 180px !important;
  }