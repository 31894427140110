* {
	font-family: "Montserrat", sans-serif;
	font-size: 16px;
}
  .back-to-top {
    position: fixed;
    visibility: hidden;
    opacity: 0;
    right: 15px;
    bottom: 15px;
    z-index: 996;
    background: #ff5821;
    width: 40px;
    height: 40px;
    border-radius: 4px;
    transition: all 0.4s;
  }
  .back-to-top i {
    font-size: 28px;
    color: #fff;
    line-height: 0;
  }
  .back-to-top:hover {
    background: #ff774a;
    color: #fff;
  }
  .back-to-top.active {
    visibility: visible;
    opacity: 1;
  }
  
  /*--------------------------------------------------------------
  # Disable aos animation delay on mobile devices
  --------------------------------------------------------------*/
  @media screen and (max-width: 768px) {
    [data-aos-delay] {
      transition-delay: 0 !important;
    }
  }

  
  /*--------------------------------------------------------------
  # Header
  --------------------------------------------------------------*/
  #header {
    height: 80px;
    transition: all 0.5s;
    z-index: 997;
   background-image: linear-gradient(to right, #fe5000, #fc451f, #f93b2f, #f4333d, #ee2d48, #e81d55, #e00c61, #d6006c, #c6007d, #af008e, #900d9d, #6723aa);
    color: #fff !important;
  }
  #header.header-fixed {
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
  }
  #header .logo h1 {
    font-size: 28px;
    margin: 0;
    line-height: 0;
    font-weight: 700;
    font-family: "Open Sans", sans-serif;
  }
  #header .logo h1 a, #header .logo h1 a:hover {
    color: #635551;
    text-decoration: none;
  }
  #header .logo img {
    padding: 0;
    margin: 0;
    max-height: 40px;
  }
  
  .scrolled-offset {
    margin-top: 70px;
  }
  
  #main {
    z-index: 3;
  }
  .btn-padrao {
    padding: 12px 24px;
    font-size: 14px;
    font-weight: 600;
    background-color: white !important;
    box-shadow: -4px -4px 14px rgb(255 255 255 / 20%), 4px 4px 14px rgb(0 0 0 / 10%);
    border-radius: 40px;
    color: #fe5000 !important;
    border: none;
    min-width: 140px;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    a{
      color: #fe5000 !important;
    }
  }
  .btn-padrao:hover {
    box-shadow: inset -2px -2px 4px rgb(255 255 255 / 75%), inset 4px 4px 8px rgb(0 0 0 / 25%);
    text-shadow: 0px 0px 4px rgb(254 80 0 / 30%);
    transition: 0.3s;
  }
  /*--------------------------------------------------------------
  # Navigation Menu
  --------------------------------------------------------------*/
  /**
  * Desktop Navigation 
  */
  .navbar {
    padding: 0;
  }
  .navbar ul {
    margin: 0;
    padding: 0;
    display: flex;
    list-style: none;
    align-items: center;
  }
  .navbar li {
    position: relative;
  }
  .navbar a, .navbar a:focus {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 6px 16px 6px 16px;
    font-size: 16px;
    font-family: "Poppins", sans-serif;
    color: #fff;
    white-space: nowrap;
    transition: 0.3s;
    text-transform: uppercase;
    font-weight: 500;
  }
  .navbar a i, .navbar a:focus i {
    font-size: 12px;
    line-height: 0;
    margin-left: 5px;
  }
  .navbar a:hover {
    border-bottom: 1px solid #fff;
  }
  .navbar a:hover, .navbar .active, .navbar .active:focus, .navbar li:hover > a {
    color: #efefef;
  }
  .navbar .getstarted, .navbar .getstarted:focus {
    background: linear-gradient(to right, #fe5000, #fc451f, #f93b2f, #f4333d, #ee2d48, #e81d55, #e00c61, #d6006c, #c6007d, #af008e, #900d9d, #6723aa);
    background-color: #ff5821;
    padding: 8px 20px;
    margin-left: 30px;
    border-radius: 4px;
    color: #fff;
  }
  .navbar .getstarted:hover, .navbar .getstarted:focus:hover {
    color: #fff;
    background: #ff6b3b;
  }
  .navbar .dropdown ul {
    display: block;
    position: absolute;
    left: 14px;
    top: calc(100% + 30px);
    margin: 0;
    padding: 10px 0;
    z-index: 99;
    opacity: 0;
    visibility: hidden;
    background: #fff;
    box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
    transition: 0.3s;
    border-radius: 4px;
  }
  .navbar .dropdown ul li {
    min-width: 200px;
  }
  .navbar .dropdown ul a {
    padding: 10px 20px;
  }
  .navbar .dropdown ul a i {
    font-size: 12px;
  }
  .navbar .dropdown ul a:hover, .navbar .dropdown ul .active:hover, .navbar .dropdown ul li:hover > a {
    color: #ff5821;
  }
  .navbar .dropdown:hover > ul {
    opacity: 1;
    top: 100%;
    visibility: visible;
  }
  .navbar .dropdown .dropdown ul {
    top: 0;
    left: calc(100% - 30px);
    visibility: hidden;
  }
  .navbar .dropdown .dropdown:hover > ul {
    opacity: 1;
    top: 0;
    left: 100%;
    visibility: visible;
  }
  @media (max-width: 1366px) {
    .navbar .dropdown .dropdown ul {
      left: -90%;
    }
    .navbar .dropdown .dropdown:hover > ul {
      left: -100%;
    }
  }
  
  /**
  * Mobile Navigation 
  */
  .mobile-nav-toggle {
    color: #473d3a;
    font-size: 28px;
    cursor: pointer;
    display: none;
    line-height: 0;
    transition: 0.5s;
  }
  .mobile-nav-toggle.bi-x {
    color: #fff;
  }
  
  @media (max-width: 991px) {
    .mobile-nav-toggle {
      display: block;
    }
  
    .navbar ul {
      display: none;
    }
  }
  .navbar-mobile {
    position: fixed;
    overflow: hidden;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background: rgba(43, 37, 35, 0.9);
    transition: 0.3s;
    z-index: 999;
  }
  .navbar-mobile .mobile-nav-toggle {
    position: absolute;
    top: 15px;
    right: 15px;
  }
  .navbar-mobile ul {
    display: block;
    position: absolute;
    top: 55px;
    right: 15px;
    bottom: 15px;
    left: 15px;
    padding: 10px 0;
    border-radius: 10px;
    background-color: #fff;
    overflow-y: auto;
    transition: 0.3s;
  }
  .navbar-mobile a, .navbar-mobile a:focus {
    padding: 10px 20px;
    font-size: 15px;
    color: #473d3a;
  }
  .navbar-mobile a:hover, .navbar-mobile .active, .navbar-mobile li:hover > a {
    color: #ff5821;
  }
  .navbar-mobile .getstarted, .navbar-mobile .getstarted:focus {
    margin: 15px;
  }
  .navbar-mobile .dropdown ul {
    position: static;
    display: none;
    margin: 10px 20px;
    padding: 10px 0;
    z-index: 99;
    opacity: 1;
    visibility: visible;
    background: #fff;
    box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
  }
  .navbar-mobile .dropdown ul li {
    min-width: 200px;
  }
  .navbar-mobile .dropdown ul a {
    padding: 10px 20px;
  }
  .navbar-mobile .dropdown ul a i {
    font-size: 12px;
  }
  .navbar-mobile .dropdown ul a:hover, .navbar-mobile .dropdown ul .active:hover, .navbar-mobile .dropdown ul li:hover > a {
    color: #ff5821;
  }
  .navbar-mobile .dropdown > .dropdown-active {
    display: block;
  }
  
  /*--------------------------------------------------------------
  # Hero Section
  --------------------------------------------------------------*/
  #hero {
    //margin-bottom: -100px;
    width: 100%;
    height: 75vh;
  //background: url("../../../../public/media/auth/happy-grils.jpg") center center;
   background: url("../../../../public/media/auth/women-listening-music-black.png") center center;
   background-size: cover;
	 background-repeat: no-repeat;
    position: relative;
    z-index: 0;
    padding: 0;
    transform: scaleX(-1);
    // filter: hue-rotate(36deg);
  }
  #hero:before {
    content: "";
    background: rgba(0, 0, 0, 0.2); // rgb(183 56 4 / 20%); //
    position: absolute;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
  }
  #hero .container {
    z-index: 2;
     -webkit-transform: scaleX(-1);
	 transform: scaleX(-1);
  //  filter:hue-rotate(-30deg)
  }
  #hero h1 {
    margin: 0 0 10px 0;
    font-size: 48px;
    font-weight: 700;
    line-height: 56px;
    color: #fff;
  }
  #hero h2 {
    color: #eee;
    margin-bottom: 50px;
    font-size: 24px;
  }
  #hero .get-started-icon {
    font-size: 24px;
    background: #ff5821;
    padding: 14px;
    color: #fff;
    border-radius: 50px;
    position: relative;
    z-index: 5;
    box-shadow: 10px 2px 15px rgba(0, 0, 0, 0.1);
  }
  #hero .btn-get-started {
    font-family: "Raleway", sans-serif;
    font-weight: 400;
    font-size: 16px;
    letter-spacing: 1px;
    display: inline-block;
    transition: 0.5s;
    margin-left: -10px;
    padding: 8px 26px 8px 26px;
    color: #fff;
    background: #584b48;
    border-radius: 0 50px 50px 0;
    position: relative;
    z-index: 4;
  }
  #hero .btn-get-started:hover {
    background: linear-gradient(to right, #fe5000, #fc451f, #f93b2f, #f4333d, #ee2d48, #e81d55, #e00c61, #d6006c, #c6007d, #af008e, #900d9d, #6723aa);
  }
 
  @media (min-width: 1024px) {
    #hero {
      background-attachment: fixed;
    }
  }
  @media (max-width: 1024px), (max-height: 768px) {
    #hero {
      //margin-bottom: -150px;
      height: 70vh;
    }
  }
  @media (max-width: 768px) {
    #hero {
      height: 70vh;
    }
    #hero h1 {
      font-size: 28px;
      line-height: 36px;
    }
    #hero h2 {
      font-size: 18px;
      line-height: 24px;
      margin-bottom: 30px;
    }
  }
  @media (max-height: 640px) {
    #hero {
      height: 120vh;
    }
  }
  
  /*--------------------------------------------------------------
  # Sections General
  --------------------------------------------------------------*/
  .highLight{
    color: #fe5000;
    font-size: 2.5rem;
  }
  section {
    padding: 60px 0;
    overflow: hidden;
  }
  
  .section-bg {
    background-color: #fff9f7;
  }
  
  .section-title {
    text-align: center;
    padding-bottom: 30px;
  }
  .section-title h2 {
    font-size: 32px;
    font-weight: 500;
    margin-bottom: 20px;
    padding-bottom: 0;
    font-family: "Poppins", sans-serif;
    color: #635551;
  }
  .section-title p {
    margin-bottom: 0;
  }
  
 
  /*--------------------------------------------------------------
  # About
  --------------------------------------------------------------*/
  .about .icon-boxes h4 {
    font-size: 18px;
    color: #7f6d68;
    margin-bottom: 15px;
  }
  .about .icon-boxes h3 {
    font-size: 28px;
    font-weight: 700;
    color: #554945;
    margin-bottom: 15px;
  }
  .about .icon-box {
    margin-top: 40px;
  }
  .about .icon-box .icon {
    float: left;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 64px;
    height: 64px;
    border: 2px solid #ffcbba;
    border-radius: 50px;
    transition: 0.5s;
    background: #fff;
  }
  .about .icon-box .icon i {
    color: #ff5821;
    font-size: 32px;
  }
  .about .icon-box:hover .icon {
    background: #ff5821;
    border-color: #ff5821;
  }
  .about .icon-box:hover .icon i {
    color: #fff;
  }
  .about .icon-box .title {
    margin-left: 85px;
    font-weight: 700;
    margin-bottom: 10px;
    font-size: 18px;
  }
  .about .icon-box .title a {
    color: #343a40;
    transition: 0.3s;
  }
  .about .icon-box .title a:hover {
    color: #ff5821;
  }
  .about .icon-box .description {
    margin-left: 85px;
    line-height: 24px;
    font-size: 14px;
    color: #000;
  }
  .about .video-box {
    background: url("../../../../public/media/auth/about.png") center center no-repeat;
    background-size: cover;
    min-height: 500px;
  }
  .about .play-btn {
    width: 94px;
    height: 94px;
    background: radial-gradient(#ff5821 50%, rgba(255, 88, 33, 0.4) 52%);
    border-radius: 50%;
    display: block;
    position: absolute;
    left: calc(50% - 47px);
    top: calc(50% - 47px);
    overflow: hidden;
  }
  .about .play-btn::after {
    content: "";
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-40%) translateY(-50%);
    width: 0;
    height: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-left: 15px solid #fff;
    z-index: 100;
    transition: all 400ms cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  .about .play-btn::before {
    content: "";
    position: absolute;
    width: 120px;
    height: 120px;
    -webkit-animation-delay: 0s;
    animation-delay: 0s;
    -webkit-animation: pulsate-btn 2s;
    animation: pulsate-btn 2s;
    -webkit-animation-direction: forwards;
    animation-direction: forwards;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: steps;
    animation-timing-function: steps;
    opacity: 1;
    border-radius: 50%;
    border: 5px solid rgba(255, 88, 33, 0.7);
    top: -15%;
    left: -15%;
    background: rgba(198, 16, 0, 0);
  }
  .about .play-btn:hover::after {
    border-left: 15px solid #ff5821;
    transform: scale(20);
  }
  .about .play-btn:hover::before {
    content: "";
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-40%) translateY(-50%);
    width: 0;
    height: 0;
    border: none;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-left: 15px solid #fff;
    z-index: 200;
    -webkit-animation: none;
    animation: none;
    border-radius: 0;
  }
  
  @-webkit-keyframes pulsate-btn {
    0% {
      transform: scale(0.6, 0.6);
      opacity: 1;
    }
    100% {
      transform: scale(1, 1);
      opacity: 0;
    }
  }
  
  @keyframes pulsate-btn {
    0% {
      transform: scale(0.6, 0.6);
      opacity: 1;
    }
    100% {
      transform: scale(1, 1);
      opacity: 0;
    }
  }
  
  
  /*--------------------------------------------------------------
  # Footer
  --------------------------------------------------------------*/
  #footer {
    color: #313030;
    font-size: 14px;
    background: #fff;
  }
  @media (min-width: 576px) {
    .col-sm-1 {
      width: 8.33333333% !important;
    }
    .col-sm-7 {
      width: 58.33333333% !important;
    }
  }