.bg_img_green{
    background-image:url('../../../../src/_metronic/assets/top-green.png');
}
.mb-n10{
    margin-bottom: -8rem !important;
}
.pt-30{
    padding-top:8rem !important;
}
.hm-370px{
    max-height: 370px  !important;
}
.w-60{
    width: 60%;
}
.w-40{
    width: 40%;
}
.bg_orange_gradent{
    background: linear-gradient(112.14deg, #FF8A00 0%, #E96922 100%)
}
.bg_pink_purple{
    background: linear-gradient(132deg, var(--pink) 0%, #e56901 100%);
}
.bg_prple_dark{
    // background: linear-gradient(112.14deg, var(--purple) 0%, var(--gray-dark) 100%);
    background: linear-gradient(112.14deg, var(--rs-violet-400) 0%, var(--bs-info-text) 100%);
    // background: linear-gradient(112.14deg, #3f51b5 0%, #009688 100%);
    background: linear-gradient(112.14deg, #3f51b5 0%, #01665d 100%);
}
.h-125px{
    height: 125px
}
.box_shadow1{
    box-shadow: 1px 1px 3px 4px var(--rs-gray-200);
}
.border-dashed.onhover:hover{
    border: 1px solid var(--bs-cyan) !important;
}


@media only screen and (min-width: 992px){
    .fs-sm-2 {
        font-size: 1.5rem !important;
    }
    .canReqImg{
        height: 200px !important;
    }
    
}

@media (min-width: 1200px) {
  .movie {
    min-height: 450px;
    max-height: 550px;
  }
  
  .movie img {
    min-height: 350px;
    max-height: 450px;
  }
}
@media only screen and (min-width: 1539px){
    .canReqImg{
        height: 300px !important;
    }
    .fs-xl-1 {
        font-size: calc(1.3rem + 0.6vw) !important;
    }
    .movie {
      min-height: 450px;
      max-height:6cm;
    }
    
    .movie img {
      min-height: 350px;
      max-height: 550px;
    }
}


//customer telco


.task-progress{
    display: flex;
	flex-direction: column;
	margin: 10px 0px;

    p {
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: #101010;
        font-size: 18PX;
      }
    

} 
  // .progress {
  //   width: 100%;
  //   -webkit-appearance: none;
  //   appearance: none;
  //   border: none;
  //   border-radius: 10px;
  //   height: 0.5rem;
  // }

  // .progress::-webkit-progress-bar {
  //   background-color: #f0f0f0;
  // }

  // .progress::-webkit-progress-bar,
  // .progress::-webkit-progress-value {
  //   border-radius: 10px;
  // }

  // .progress::-moz-progress-bar {
  //   border-radius: 10px;
  // }

  // .progress1::-webkit-progress-value {
  //   background: var( --bs-blue);
  // }

  // .progress2::-webkit-progress-value {
  //   background: var( --bs-success);
  // }

  // .progress3::-webkit-progress-value {
  //   background: var( --bs-danger);
  // }

  // .radialProgressBar {
  //   border-radius: 50%;
  //   -webkit-transform:translate(-25%, 0%);
	// transform:translate(-25%, 0%);
	// width: 100px;
	// height: 100px;
	// display: flex;
	// background: #ddd;
	// float: left;
  // }
  // .radialProgressBar .overlay {
  //   border-radius: 50%;
  //   width: 80px;
  //   height: 80px;
  //   margin: auto;
  //   background: #000000;
  //   text-align: center;
  //   padding-top: 30%;
  // }

  // .progress-60 {
  //   background-image: -webkit-linear-gradient(left, #fe5000 50%, transparent 50%), -webkit-linear-gradient(36deg, #fe5000 50%, #ddd 50%);
  //   background-image: linear-gradient(90deg, #fe5000 50%, transparent 50%), linear-gradient(54deg, #fe5000 50%, #ddd 50%);
  // }


  main {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  
  .movie {
    width: 100%;
    box-shadow: 0 4px 5px rgba(0, 0, 0, 0.2);
    position: relative;
    overflow: hidden;
    border-radius: 5px;
    min-height: 380px;
    max-height: 480px;
  }
  
  .movie img {
    width: 100%;
    min-height: 280px;
    max-height: 380px;
  }
  
  .movie-info {
    color: white;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.5rem;
    letter-spacing: 0.5px;
  }
  
  .movie-info h4 {
    margin-top: 0;
  }
  
  .movie-info span {
    background-color: var(--primary-color);
    padding: 0.25rem 0.5rem;
    border-radius: 3px;
    font-weight: bold;
  }
  
  .movie-info span.green {
    color: lightgreen;
  }
  
  .movie-info span.red {
    color: red;
  }
  
  .movie-info span.orange {
    color: orange;
  }
  
  .overview {
    background-color: white;
    color: #000000;
    padding: 2rem;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    max-height: 100%;
    transform: translateY(101%);
    transition: transform 0.3s ease-in;
  }
  
  .movie:hover .overview {
    transform: translateY(0);
  }
  

  .progress_circle{
    .progress {
      width: 130px;
      height: 130px !important;
      float: left; 
      line-height: 130px;
      background: none;
      margin: 20px;
      box-shadow: none;
      position: relative;
      z-index: 0;
    }
    .progress:after {
      content: "";
      width: 100%;
      height: 100%;
      border-radius: 50%;
      border: 10px solid #fff;
      position: absolute;
      top: 0;
      left: 0;
    }
    .progress>span {
      width: 50%;
      height: 100%;
      overflow: hidden;
      position: absolute;
      top: 0;
      z-index: 1;
    }
    .progress .progress-left {
      left: 0;
    }
    .progress .progress-bar {
      width: 100%;
      height: 100%;
      background: none;
      border-width: 10px;
      border-style: solid;
      position: absolute;
      top: 0;
    }
    .progress .progress-left .progress-bar {
      left: 100%;
      border-top-right-radius: 80px;
      border-bottom-right-radius: 80px;
      border-left: 0;
      -webkit-transform-origin: center left;
      transform-origin: center left;
    }
    .progress .progress-right {
      right: 0;
    }
    .progress .progress-right .progress-bar {
      left: -100%;
      border-top-left-radius: 80px;
      border-bottom-left-radius: 80px;
      border-right: 0;
      -webkit-transform-origin: center right;
      transform-origin: center right;
      animation: loading-1 1.8s linear forwards;
    }
    .progress .progress-value {
      width: 90%;
      height: 90%;
      border-radius: 50%;
      font-size: 20px;
      line-height: 135px;
      text-align: center;
      position: absolute;
      top: 5%;
      left: 5%;
    }
    .progress.orange .progress-bar {
      border-color: #fe5000;
    }
    .progress.orange .progress-right .progress-bar {
      animation: loading-1 1.8s linear forwards;
    }
    .progress.orange .progress-left .progress-bar {
      animation: none;
    }
    .progress.pink .progress-bar {
      border-color: #E42855;
    }
    .progress.pink .progress-left .progress-bar {
      animation: loading-2 1.5s linear forwards 1.8s;
    }
    .progress.blue .progress-bar {
      border-color: #6723aa;
    }
    .progress.blue .progress-right .progress-bar {
      animation: loading-3 1.8s linear forwards;
    }
    .progress.blue .progress-left .progress-bar {
      animation: none;
    }
    
      
    @keyframes loading-1 {
      0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
      }
      100% {
        -webkit-transform: rotate(180deg);
        transform: rotate(180deg);
      }
    }
    @keyframes loading-2 {
      0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
      }
      100% {
        -webkit-transform: rotate(144deg);
        transform: rotate(144deg);
      }
    }
    @keyframes loading-3 {
      0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
      }
      100% {
        -webkit-transform: rotate(135deg);
        transform: rotate(135deg);
      }
    }
  }




/* Text */
// .city,
// .day,
// .grade {
//   font-family: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
// }

.city {
  color: whitesmoke;
  font-size: 12px;
  font-weight: 300;
}

.day {
  color: whitesmoke;
  font-size: 20px;
  font-weight: 900;
}

.grade {
  color: whitesmoke;
  font-size: 40px;
  font-weight: 400;
}

/* SUN */

.sun {
  position: relative;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background-color: #ffcc00;
  box-shadow: 0 0 20px rgba(255, 204, 0, 0.7);
  animation: pulse 2s ease-in-out infinite, glow 3s ease-in-out infinite;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes glow {
  0% {
    box-shadow: 0 0 20px rgba(255, 204, 0, 0.7);
  }
  50% {
    box-shadow: 0 0 30px rgba(255, 204, 0, 0.9);
  }
  100% {
    box-shadow: 0 0 20px rgba(255, 204, 0, 0.7);
  }
}

// .container-sun,
// .container-sun-and-cloud,
// .container-rain,
// .container-cloud,
// .container-storm{
// }

.box-shadow-sun {
  position: absolute;
  top: 20px;
  right: 40px;
  width: 95%;
  height: 85%;
  background-color: #ffcc00;
  border-radius: 20px;
  opacity: 0.9;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
  z-index: -1;
}

.content-sun, .content-storm, .content-cloud, .content-rain , .content-sun-and-cloud{
  position: relative;
  background-color: #60a7c4;
  border-radius: 7px;
  width: calc(100% - 20px);
  height: 130px;
  padding: 20px 50px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
}

.image-sun {
  flex: 1;
  height: auto;
  width: 80%;
}

.text-sun {
  margin-bottom: 20px;
  flex: 1;
}

.text-sun p {
  margin-top: 13px;
  margin-left: 20px;
  margin-bottom: -15px;
}



/* SUN & CLOUD */
.sun-inner,
.cloud-sun-inner {
  position: absolute;
  top: 0;
  left: 0;
}

.sun-inner {
  position: relative;
  width: 80px;
  top: 20px;
  height: 80px;
  border-radius: 50%;
  background-color: #ffcc00;
  box-shadow: 0 0 20px rgba(255, 204, 0, 0.7);
  animation: pulse 2s ease-in-out infinite, glow 3s ease-in-out infinite;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes glow {
  0% {
    box-shadow: 0 0 20px rgba(255, 204, 0, 0.7);
  }
  50% {
    box-shadow: 0 0 30px rgba(255, 204, 0, 0.9);
  }
  100% {
    box-shadow: 0 0 20px rgba(255, 204, 0, 0.7);
  }
}

.cloud-sun-inner {
  position: relative;
  width: 125px;
  height: 65px;
  border-radius: 90px;
  background-color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.cloud-sun-inner::before,
.cloud-sun-inner::after {
  content: "";
  position: absolute;
  width: 72px;
  height: 72px;
  background-color: white;
  border-radius: 36px;
}

.cloud-sun-inner::before {
  top: -30px;
  left: 30px;
}

.cloud-sun-inner::after {
  top: -18px;
  right: 30px;
}

@keyframes moveCloud {
  from {
    transform: translateX(-10px);
  }
  to {
    transform: translateX(10px);
  }
}

.cloud-sun-inner {
  animation: moveCloud 4s linear infinite alternate;
}



.box-shadow-sun-and-cloud {
  position: absolute;
  top: 20px;
  right: 40px;
  width: 95%;
  height: 85%;
  background-color: white;
  border-radius: 20px;
  opacity: 0.9;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
  z-index: -1;
}



.image-sun-and-cloud {
  flex: 1;
  height: auto;
  margin-bottom: 20px;
  width: 80%;
}

.text-sun-and-cloud {
  margin-bottom: 20px;
  flex: 1;
}

.text-sun-and-cloud p {
  margin-top: 13px;
  margin-left: 20px;
  margin-bottom: -15px;
}


/* RAIN */


.box-shadow-rain {
  position: absolute;
  top: 20px;
  right: 40px;
  width: 95%;
  height: 85%;
  background-color: #015b7fe5;
  border-radius: 20px;
  opacity: 0.9;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
  z-index: -1;
}

.image-rain {
  flex: 1;
  height: auto;
  margin-top: 20px;
  width: 80%;
}

.text-rain {
  margin-bottom: 20px;
  flex: 1;
}

.text-rain p {
  margin-top: 13px;
  margin-left: 20px;
  margin-bottom: -15px;
}

.raindrop {
  position: absolute;
  width: 8px;
  height: 20px;
  background-color: #015b7f;
  opacity: 0.8;
  left: calc(50% - 4px + (100px * var(--offset)));
  top: calc(100% + 30px);
}

.raindrop:nth-child(1) {
  --offset: 0;
  animation: fallRain 1.5s linear infinite;
}

.raindrop:nth-child(2) {
  --offset: 0.2;
  animation: fallRain 1.8s linear infinite;
}

.raindrop:nth-child(3) {
  --offset: 0.4;
  animation: fallRain 1.7s linear infinite;
}

@keyframes fallRain {
  0% {
    transform: translateY(-10px);
    opacity: 0.8;
  }
  100% {
    transform: translateY(200px);
    opacity: 0.4;
  }
}

.rain-cloud {
  position: relative;
  width: 130px;
  height: 70px;
  border-radius: 90px;
  background-color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.rain-cloud::before,
.rain-cloud::after {
  content: "";
  position: absolute;
  width: 72px;
  height: 72px;
  background-color: white;
  border-radius: 36px;
}

.rain-cloud::before {
  top: -30px;
  left: 30px;
}

.rain-cloud::after {
  top: -18px;
  right: 30px;
}

@keyframes moveCloud {
  from {
    transform: translateX(-10px);
  }
  to {
    transform: translateX(10px);
  }
}

.rain-cloud {
  animation: moveCloud 4s linear infinite alternate;
}

/* CLOUD */

.cloud {
  position: relative;
  width: 130px;
  height: 70px;
  border-radius: 90px;
  background-color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.cloud::before,
.cloud::after {
  content: "";
  position: absolute;
  width: 72px;
  height: 72px;
  background-color: white;
  border-radius: 36px;
}

.cloud::before {
  top: -30px;
  left: 30px;
}

.cloud::after {
  top: -18px;
  right: 30px;
}

@keyframes moveCloud {
  from {
    transform: translateX(-10px);
  }
  to {
    transform: translateX(10px);
  }
}

.cloud {
  animation: moveCloud 4s linear infinite alternate;
}



.box-shadow-cloud {
  position: absolute;
  top: 20px;
  right: 40px;
  width: 95%;
  height: 85%;
  background-color: #012b3b;
  border-radius: 20px;
  opacity: 0.9;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
  z-index: -1;
}

.image-cloud {
  flex: 1;
  height: auto;
  margin-top: 20px;
  width: 80%;
}

.text-cloud {
  margin-bottom: 20px;
  flex: 1;
}

.text-cloud p {
  margin-top: 13px;
  margin-left: 20px;
  margin-bottom: -15px;
}


/* STORM */
.bolt {
  margin: 2rem auto;
  border-top: 75px solid yellow;
  border-left: 5px solid transparent;
  border-right: 10px solid transparent;
  height: 0px;
  width: 15px;
  transform: skew(-25deg) scale(0.8);
  opacity: 0.7;
  animation: appearBolt 4s ease-in-out forwards infinite;
}

@keyframes appearBolt {
  0%,
  100% {
    opacity: 0.7;
  }
  50% {
    opacity: 1;
  }
}

.bolt:after {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  left: 15px;
  top: -21px;
  border-top: 75px solid yellow;
  border-right: 26.25px solid transparent;
}

.raindrop-storm {
  position: absolute;
  width: 8px;
  height: 20px;
  background-color: #015b7f;
  opacity: 0.8;
  left: calc(50% - 4px + (100px * var(--offset)));
  top: calc(100% + 30px);
}

.raindrop-storm:nth-child(1) {
  --offset: 0;
  animation: fallRain 1.5s linear infinite;
}

.raindrop-storm:nth-child(2) {
  --offset: 0.2;
  animation: fallRain 1.8s linear infinite;
}

.raindrop-storm:nth-child(3) {
  --offset: 0.4;
  animation: fallRain 1.7s linear infinite;
}

@keyframes fallRain {
  0% {
    transform: translateY(-10px);
    opacity: 0.8;
  }
  100% {
    transform: translateY(200px);
    opacity: 0.4;
  }
}

.cloud-storm {
  position: relative;
  width: 130px;
  height: 70px;
  border-radius: 90px;
  background-color: grey;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.cloud-storm::before,
.cloud-storm::after {
  content: "";
  position: absolute;
  width: 72px;
  height: 72px;
  background-color: grey;
  border-radius: 36px;
}

.cloud-storm::before {
  top: -30px;
  left: 30px;
}

.cloud-storm::after {
  top: -18px;
  right: 30px;
}

@keyframes moveCloud {
  from {
    transform: translateX(-10px);
  }
  to {
    transform: translateX(10px);
  }
}

.cloud-storm {
  animation: moveCloud 4s linear infinite alternate;
}


.box-shadow-storm {
  position: absolute;
  top: 20px;
  right: 40px;
  width: 95%;
  height: 85%;
  background-color: grey;
  border-radius: 20px;
  opacity: 0.9;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
  z-index: -1;
}


.image-storm {
  flex: 1;
  height: auto;
  margin-bottom: 5px;
  width: 80%;
}

.text-storm {
  margin-bottom: 20px;
  flex: 1;
}

.text-storm p {
  margin-top: 13px;
  margin-left: 20px;
  margin-bottom: -15px;
}